<template>
  <Page class="bg-white" toolbar>
    <!-- 导航栏 -->
    <NavBar title="选择收款信息"></NavBar>
    <div class="page-content">
      <Tabs line-width="50%" sticky v-model="activeRef" @click="handleChangeTab" class="flex-1">
        <Tab title="我的" name="DRIVER">
          <div v-if="perListRef.length" class="pl-16 bg-white">
            <BankItem
              v-on:handleCheckCarrier="handleCheckCarrier"
              v-for="item in perListRef"
              :key="item.payeeId"
              :itemData="item"
              :payeeId="selectCarrier.payeeId"
            />
          </div>
          <Empty v-else imgWidth="0.84rem" :image="noContract" description="当前无账户" />
        </Tab>
        <Tab title="车队长" name="CARRIER">
          <div v-if="carListRef.length" class="pl-16 bg-white">
            <BankItem
              v-on:handleCheckCarrier="handleCheckCarrier"
              v-for="item in carListRef"
              :key="item.payeeId"
              :itemData="item"
              :payeeId="selectCarrier.payeeId"
            />
          </div>
          <Empty v-else imgWidth="0.84rem" :image="noContract" description="当前无账户" />
        </Tab>
      </Tabs>
    </div>
    <template v-slot:toolbar>
      <Button size="large" @click="handleSureCarrier" type="primary">确认选择</Button>
    </template>
  </Page>
</template>
<script>
import { defineComponent, computed, ref, onBeforeMount, nextTick } from '@vue/composition-api';
import { useSourceStore } from '@/store/source';
import { Button, Tab, Tabs, Toast } from 'vant';
import NavBar from '@/components/NavBar/index';
import Empty from '@components/Empty/index.vue';
import BankItem from '../components/bankItem';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  components: {
    Empty,
    Tab,
    Tabs,
    Button,
    NavBar,
    BankItem
  },
  setup(_, ctx) {
    const sourceStore = useSourceStore();
    const activeRef = ref('DRIVER');
    const perListRef = computed(() => sourceStore.perList); // 个人银行卡列表
    const carListRef = computed(() => sourceStore.carList); // 车队长行卡列表
    const selectCarrier = ref({
      payeeId: 0
    });
    const noContract = require('@/assets/htkb.png');

    onBeforeMount(async () => {
      await sourceStore.queryCarrierList();
    });

    const handleChangeTab = e => {
      activeRef.value = e;
    };

    const handleCheckCarrier = item => {
      selectCarrier.value = item;
    };

    const handleSureCarrier = () => {
      const { $router } = ctx.root;
      if (selectCarrier?.value?.payeeId === 0) {
        Toast.fail('请选择收款账户！');
      } else {
        sourceStore.updateInfo(selectCarrier.value);
        nextTick(() => {
          $router.push({
            path: PageEnum.SOURCE_INFORMATION,
            query: {
              id: $router.currentRoute.query.id
            }
          });
        });
      }
    };

    return {
      activeRef,
      perListRef,
      carListRef,
      selectCarrier,
      noContract,
      handleChangeTab,
      handleSureCarrier,
      handleCheckCarrier
    };
  }
});
</script>
<style lang="less">
.van-tabs__content {
  height: calc(~'100vh - 1.64rem');
}
</style>
