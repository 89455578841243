<template>
  <div class="bank-item py-16 pr-16" @click="$emit('handleCheckCarrier', itemData)">
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <img class="mr-16" :src="getBankIconByCode(itemData.bankCode)" alt="" />
        <div class="d-flex flex-column">
          <div class="fw-500">{{ itemData.bankName }} ({{ itemData.name }})</div>
          <div class="font-12 text_muted mt-8">{{ itemData.bankAccount }}</div>
        </div>
      </div>
      <div v-if="itemData.payeeId === payeeId" class="pt-10">
        <Icon name="xz" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { getBankIconByCode } from '@/views/mine/constants';

export default defineComponent({
  props: {
    itemData: {
      type: Object,
      default: () => {}
    },
    payeeId: {
      type: String | Number
    }
  },
  setup() {
    return {
      getBankIconByCode
    };
  }
});
</script>
<style lang="less">
.bank-item {
  height: 0.72rem;
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  img {
    width: 0.24rem;
    height: 0.24rem;
  }
}
</style>
